<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2020-01"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
    </v-toolbar>
    <v-card-title class="title">
      Activations
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="minPayment"
        :items="minPayments"
        item-text="text"
        item-value="value"
        label="With topup/bill payment"
        box
      />
    </v-card-text>
    <v-alert
      :value="activationAlert"
      type="error"
      transition="scale-transition"
    >
      {{ activationAlertMessage }}
    </v-alert>
    <v-data-table
      :headers="activationHeaders"
      :items="activations"
      :loading="loadingActivations"
      item-key="activation"
      class="elevation-1"
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p1 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p2 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p3 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p4 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p5 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.p6 }}
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.total }}
          </td>
          <td class="text-xs-center">
            {{ props.item.totalDealers }}
          </td>
        </tr>
      </template>
      <template #footer>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td class="text-xs-center primary--text">
          {{ activationP1 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP2 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP3 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP4 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP5 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP6 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ activationP1 + activationP2 + activationP3 + activationP4 + activationP5 + activationP6 }}
        </td>
        <td class="text-xs-center primary--text">
          {{ totalDealers }}
        </td>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      E-recharge Sales
    </v-card-title>
    <v-alert
      :value="revenueAlert"
      type="error"
      transition="scale-transition"
    >
      {{ revenueAlertMessage }}
    </v-alert>
    <v-data-table
      :headers="revenueHeaders"
      :items="revenue"
      :loading="loadingRevenue"
      item-key="revenue"
      class="elevation-1"
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p1).toFixed(2) }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p2).toFixed(2) }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p3).toFixed(2) }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p4).toFixed(2) }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p5).toFixed(2) }}
          </td>
          <td class="text-xs-center">
            {{ Number(props.item.p6).toFixed(2) }}
          </td>
          <td class="text-xs-center primary--text">
            {{ Number(props.item.total).toFixed(2) }}
          </td>
        </tr>
      </template>
      <template #footer>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP1).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP2).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP3).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP4).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP5).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP6).toFixed(2) }}
        </td>
        <td class="text-xs-center primary--text">
          {{ Number(revenueP1 + revenueP2 + revenueP3 + revenueP4 + revenueP5 + revenueP6).toFixed(2) }}
        </td>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: 'Reports', disabled: false, to: '/support/dmt_reports',
        },
        {
          text: this.title, disabled: true,
        },
      ],
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      activationHeaders: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: '1-5',
          align: 'center',
          sortable: true,
          value: 'p1',
        },
        {
          text: '6-10',
          align: 'center',
          sortable: true,
          value: 'p2',
        },
        {
          text: '11-15',
          align: 'center',
          sortable: true,
          value: 'p3',
        },
        {
          text: '16-20',
          align: 'center',
          sortable: true,
          value: 'p4',
        },
        {
          text: '21-25',
          align: 'center',
          sortable: true,
          value: 'p5',
        },
        {
          text: '26-31',
          align: 'center',
          sortable: true,
          value: 'p6',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
        {
          text: 'Total Dealers',
          align: 'center',
          sortable: true,
          value: 'totalDealers',
        },
      ],
      revenueHeaders: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: '1-5',
          align: 'center',
          sortable: true,
          value: 'p1',
        },
        {
          text: '6-10',
          align: 'center',
          sortable: true,
          value: 'p2',
        },
        {
          text: '11-15',
          align: 'center',
          sortable: true,
          value: 'p3',
        },
        {
          text: '16-20',
          align: 'center',
          sortable: true,
          value: 'p4',
        },
        {
          text: '21-25',
          align: 'center',
          sortable: true,
          value: 'p5',
        },
        {
          text: '26-31',
          align: 'center',
          sortable: true,
          value: 'p6',
        },
        {
          text: 'Total',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      activations: [],
      revenue: [],
      loadingActivations: false,
      loadingRevenue: false,
      maxDate: '',
      menu: false,
      minPayment: 30,
      minPayments: [
        {
          text: 'RM30',
          value: 30,
        },
        {
          text: 'RM10',
          value: 10,
        },
        {
          text: 'All registrations',
          value: 0,
        },
      ],
      monthOf: null,
      activationP1: 0,
      activationP2: 0,
      activationP3: 0,
      activationP4: 0,
      activationP5: 0,
      activationP6: 0,
      revenueP1: 0,
      revenueP2: 0,
      revenueP3: 0,
      revenueP4: 0,
      revenueP5: 0,
      revenueP6: 0,
      totalDealers: 0,
      target: 0,
      noLeadDealer: false,
      activationAlert: false,
      activationAlertMessage: null,
      revenueAlert: false,
      revenueAlertMessage: null,
    }
  },
  watch: {
    minPayment: function (val) {
      this.resetActivations()
      this.getLeadDealerActivations(this.monthOf)
    },
    monthOf: function (val) {
      this.resetRevenue()
      this.resetActivations()
      this.getLeadDealerActivations(val)
    },
    revenue: {
      handler: function (revenue) {
        if (!revenue || revenue.length >= this.activations.length) {
          this.loadingRevenue = false
          return
        }
        this.fetchLeadDealerRevenue()
      },
      deep: true,
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    resetActivations: function () {
      this.activationAlert = false
      this.activationAlertMessage = null
      this.loadingActivations = false
      this.activations = []
      this.activationP1 = 0
      this.activationP2 = 0
      this.activationP3 = 0
      this.activationP4 = 0
      this.activationP5 = 0
      this.activationP6 = 0
      this.totalDealers = 0
    },
    resetRevenue: function () {
      this.revenueAlert = false
      this.revenueAlertMessage = null
      this.target = 0
      this.noLeadDealer = false
      this.loadingRevenue = false
      this.revenue = []
      this.revenueP1 = 0
      this.revenueP2 = 0
      this.revenueP3 = 0
      this.revenueP4 = 0
      this.revenueP5 = 0
      this.revenueP6 = 0
    },
    sleep: function sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    fetchLeadDealerRevenue: function () {
      if (this.target >= this.activations.length) return
      if (this.activations[this.target].dealer === '-' || this.activations[this.target].dealer === '_') {
        if (!this.noLeadDealer) {
          this.noLeadDealer = true
          this.getLeadDealerRevenue(this.monthOf, this.activations[this.target++].dealer)
        } else {
          this.target++
        }
      } else {
        this.getLeadDealerRevenue(this.monthOf, this.activations[this.target++].dealer)
      }
    },
    getLeadDealerActivations: function (month) {
      const activations = []
      this.loadingActivations = true
      const params = createGetParams({
        leadDealer: this.value,
        month: month,
        type: 'activations',
        minPayment: this.minPayment,
      })

      this.$rest.get('getLeadDealerPerformance.php', params)
        .then(function (response) {
          response.data.forEach(input => {
            const activation = {
              name: input.name,
              dealer: input.dealer,
              totalDealers: input.totalDealers,
              p1: input.period[0],
              p2: input.period[1],
              p3: input.period[2],
              p4: input.period[3],
              p5: input.period[4],
              p6: input.period[5],
              total: 0,
            }
            activation.total = activation.p1 + activation.p2 + activation.p3 + activation.p4 + activation.p5 + activation.p6

            this.activationP1 += activation.p1
            this.activationP2 += activation.p2
            this.activationP3 += activation.p3
            this.activationP4 += activation.p4
            this.activationP5 += activation.p5
            this.activationP6 += activation.p6
            this.totalDealers += activation.totalDealers

            activations.push(activation)
            this.loadingActivations = false
          })

          this.activations = activations
          if (this.revenue.length !== this.activations.length) {
            for (let i = 0; i < 5; i++) {
              this.fetchLeadDealerRevenue()
              this.sleep(100)
            }
          }
        }.bind(this)).catch(error => {
          this.loadingActivations = false
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.activationAlertMessage = message
          this.activationAlert = true
        })
    },
    getLeadDealerRevenue: function (month, dealer) {
      this.loadingRevenue = true
      const params = createGetParams({
        leadDealer: dealer,
        month: month,
        type: 'revenue',
      })

      this.$rest.get('getLeadDealerPerformance.php', params)
        .then(function (response) {
          response.data.forEach(input => {
            const erecharge = {
              name: input.name,
              dealer: input.dealer,
              p1: input.period[0] * 1,
              p2: input.period[1] * 1,
              p3: input.period[2] * 1,
              p4: input.period[3] * 1,
              p5: input.period[4] * 1,
              p6: input.period[5] * 1,
              total: 0,
            }
            erecharge.total = erecharge.p1 + erecharge.p2 + erecharge.p3 + erecharge.p4 + erecharge.p5 + erecharge.p6

            this.revenueP1 += erecharge.p1
            this.revenueP2 += erecharge.p2
            this.revenueP3 += erecharge.p3
            this.revenueP4 += erecharge.p4
            this.revenueP5 += erecharge.p5
            this.revenueP6 += erecharge.p6

            this.revenue.push(erecharge)
          })
        }.bind(this)).catch(error => {
          this.loadingRevenue = false
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.revenueAlertMessage = message
          this.revenueAlert = true
        })
    },
  },
}
</script>
